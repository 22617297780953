import React from 'react';
import Masonry from 'react-masonry-css';

import Button from '../button';

import './box-grid.module.scss';

const WithLink = ({ link, children }) =>
  link ? (
    <a href={link} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    children
  );

const BoxGrid = ({ data, spacing }) => {
  const columnCount = data.primary.number_of_columns ? 2 : 1;

  const breakpointColumnsObj = {
    default: columnCount,
    780: 1,
  };

  return (
    <div className="o-container-fluid">
      <div className="o-row">
        <div
          className={`o-col ${
            columnCount === 1
              ? 'o-col-md-8 o-col-md--offset-2'
              : 'o-col-lg-10 o-col-lg--offset-1'
          } ${spacing}`}
        >
          <Masonry
            className="box-grid"
            columnClassName="box-grid__column"
            breakpointCols={breakpointColumnsObj}
          >
            {data.items.map((item, i) => {
              // If less than 10, add a 0 before the digit
              const itemCount = ('0' + (i + 1)).slice(-2);

              // Set every 2, 5, 8, etc. to purple
              const isPurple = (i + 1) % 3 === 2;
              // Set every 3, 6, 9, etc. to yellow
              const isYellow = (i + 1) % 3 === 0;

              return (
                <div
                  styleName={`box-grid__item ${
                    isPurple
                      ? 'box-grid__item--purple'
                      : isYellow
                      ? 'box-grid__item--yellow'
                      : ''
                  }`}
                  key={i}
                >
                  <div styleName="box-grid__content-wrapper">
                    <div styleName="box-grid__count">{itemCount}</div>

                    <WithLink link={item.grid_link.url}>
                      <h2 className="title--small">{item.grid_title.text}</h2>
                    </WithLink>

                    <div
                      dangerouslySetInnerHTML={{ __html: item.grid_body.html }}
                    />
                  </div>

                  {item.grid_link.url && (
                    <Button
                      text={'Learn more'}
                      url={item.grid_link.url}
                      target="_blank"
                      theme={isPurple || isYellow ? 'bg--dark' : 'bg'}
                      withArrow
                    />
                  )}
                </div>
              );
            })}
          </Masonry>
        </div>
      </div>
    </div>
  );
};

export default BoxGrid;
