import React from 'react';

import './rich-text.module.scss';

const RichText = ({ data }) => {
  return (
    <div
      className={`o-col o-col-sm-md-10 o-col-md-8 o-col-xl-6 o-col-sm-md--offset-1 o-col-md--offset-2 o-col-xl--offset-3`}
      styleName="rich-text"
      dangerouslySetInnerHTML={{ __html: data.primary.rich_text.html }}
    />
  );
};

export default RichText;
