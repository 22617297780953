import React from 'react';

import './large-image.module.scss';

const LargeImage = ({ data }) => {
  return (
    <div
      className={`o-col o-col-md-10 o-col-xl-8 o-col-md--offset-1 o-col-xl--offset-2`}
      styleName="large-image"
    >
      {data.items.map((item, i) => (
        <img src={item.image.fluid.src} alt={item.image.alt} key={i} />
      ))}
    </div>
  );
};

export default LargeImage;
