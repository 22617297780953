import React from 'react';
import Button from '../button';

import './item-list.module.scss';

const WithLink = ({ link, children }) =>
  link ? (
    <a href={link} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    children
  );

const ItemList = ({ data, spacing }) => (
  <ul
    className={`o-col o-col-md-10 o-col-xl-8 o-col-md--offset-1 o-col-xl--offset-2 ${spacing}`}
    styleName="item__list"
  >
    {data.items.map((item, i) => (
      <li styleName="item__list-item" key={i}>
        <div styleName="item__content-wrapper">
          <WithLink link={item.link.url}>
            <h2 className="title--small">{item.title.text}</h2>
          </WithLink>
          <div
            styleName={`item__body ${
              item.link.url ? 'item__body--excerpt' : ''
            }`}
            dangerouslySetInnerHTML={{ __html: item.excerpt.html }}
          />
        </div>

        {item.link.url && (
          <Button
            text={'Read more'}
            url={item.link.url}
            target="_blank"
            theme={'bg'}
            withArrow
          />
        )}
      </li>
    ))}
  </ul>
);

export default ItemList;
