import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageTitle from '../components/page-title';
import ItemList from '../components/item-list';
import BoxGrid from '../components/box-grid';
import Quotes from '../components/quotes';
import RichText from '../components/rich-text';
import LargeImage from '../components/large-image';

const BasicPage = ({ data }) => {
  const pageContent = data.prismicBasicPage.data;
  const slices = pageContent.body;

  const sliceComponents = {
    article_list: ItemList,
    article_grid: BoxGrid,
    quotes: Quotes,
    rich_text: RichText,
    image: LargeImage,
  };

  return (
    <Layout>
      <SEO title={pageContent.page_title.text} />
      <PageTitle
        spacing="u-mar-top-xxl md:u-mar-top-xxxl u-mar-bottom-xxl"
        title={pageContent.page_title.text}
      />

      <div className="o-container-fluid">
        <div className="o-row">
          {slices.map((slice, index) => {
            const SliceComponent = sliceComponents[slice.slice_type];

            if (SliceComponent) {
              return (
                <SliceComponent
                  spacing="u-mar-bottom-xxxl"
                  data={slice}
                  key={`slice-${index}`}
                  isSlice
                />
              );
            }
          })}
        </div>
      </div>
    </Layout>
  );
};

export default withPreview(BasicPage);

export const query = graphql`
  query($uid: String!) {
    prismicBasicPage(uid: { eq: $uid }) {
      _previewable
      data {
        page_title {
          text
        }
        body {
          ... on PrismicBasicPageBodyArticleList {
            slice_type
            items {
              title {
                text
              }
              excerpt {
                html
                text
              }
              link {
                url
              }
            }
          }
          ... on PrismicBasicPageBodyArticleGrid {
            slice_type
            primary {
              number_of_columns
            }
            items {
              grid_title {
                text
              }
              grid_body {
                html
              }
              grid_link {
                url
              }
            }
          }
          ... on PrismicBasicPageBodyRichText {
            slice_type
            primary {
              rich_text {
                html
              }
            }
          }
          ... on PrismicBasicPageBodyImage {
            slice_type
            items {
              image {
                fluid(maxWidth: 800) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
            }
          }
          ... on PrismicBasicPageBodyQuotes {
            slice_type
            primary {
              quotes_reference {
                document {
                  ... on PrismicQuotes {
                    data {
                      quote {
                        quote_text {
                          html
                        }
                        quote_author {
                          html
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
